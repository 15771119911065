import * as React from 'react'
import { useEffect, useState } from 'react'
import { TextField, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { Link, navigate } from 'gatsby'
import { TransparentCard } from '../ui/TransparentCard'
import WaitButton from '../ui/WaitButton'
import { getTokenUserId, unsetLocalToken } from '../../utils/client'
import queryString from 'query-string'
import { useCompleteResetPasswordMutation } from '../../apollo/generated'
import Swal from 'sweetalert2'
const logo = require('../../images/logo-white.png')

const CompleteResetPassword = () => {
  const [code, setCode] = useState('')
  const [password, setPassword] = useState('')
  const [confirm, setConfirm] = useState('')
  const queryStrings =
    typeof window !== 'undefined' && queryString.parse(window.location.search)
  const [
    _completeResetPassword,
    { loading }
  ] = useCompleteResetPasswordMutation()

  const completeResetPassword = async () => {
    try {
      if (password !== confirm) {
        return Swal.fire('Passwords Must Match', undefined, 'error')
      }
      await _completeResetPassword({ variables: { code, password } })
      await Swal.fire('Success', 'Please login to continue')
      navigate('/')
    } catch (e) {
      Swal.fire('An Error Occurred', e.message, 'error')
    }
  }

  useEffect(() => {
    if (getTokenUserId()) {
      unsetLocalToken()
     }
    setCode(
      typeof queryString === 'string'
        ? ((queryStrings as any) as string)
        : ((queryStrings as any).code as string)
    )
  }, [queryStrings, queryStrings.code])

  return (
    <Internal>
      <LogoAvatar src={logo} />
      <TransparentCard>
        <form
          onSubmit={e => {
            e.preventDefault()
            completeResetPassword()
          }}
        >
          <FormInput
            label="New Password"
            onChange={e => setPassword(e.target.value)}
            margin="normal"
            variant="outlined"
            value={password}
            type="password"
            required
          />
          <FormInput
            label="Confirm Password"
            onChange={e => setConfirm(e.target.value)}
            margin="normal"
            variant="outlined"
            value={confirm}
            type="password"
            required
          />
          <WaitButton
            disabled={loading || !password || !confirm || password !== confirm}
            inProgress={loading}
          >
            Change Password
          </WaitButton>
        </form>
      </TransparentCard>
      <Typography variant="subtitle1" style={{ marginTop: 12, color: 'white' }}>
        Didn't mean to come here?{' '}
        <Link to={`/`} style={{ color: '#8B6137' }}>
          Back To Login
        </Link>
      </Typography>
    </Internal>
  )
}

const Internal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation: fadeIn 0.5s ease-out;

  > *:nth-child(2) {
    max-width: 92vw;
  }
`

const LogoAvatar = styled.img.attrs({ resizeMode: 'contain' })`
  width: 280px;
  max-width: 90vw;
  margin-bottom: 22px;
`

const FormInput = styled(TextField)`
  width: 100%;
`

export default CompleteResetPassword
