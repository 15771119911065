import React from 'react'

import SEO from '../components/seo/seo'
import MainWrapper from '../components/ui/MainWrapper'
import CompleteResetPassword from '../components/completeResetPassword'

const CompleteResetPasswordPage = () => (
	<MainWrapper>
		<SEO
			title="Complete Reset Password"
			keywords={['complete reset password', '']}
		/>
		<CompleteResetPassword />
	</MainWrapper>
)

export default CompleteResetPasswordPage
